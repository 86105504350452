<template>
   <section class="invoice-preview-wrapper">    
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
      <b-card class="invoice-actions" no-body>
        <b-card-body>
          <b-row>
            <b-col cols="12" md="3">
              <label for="date_start">Fecha Inicio</label>
              <flat-pickr id="date_start" v-model="filter.start" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                placeholder="DD/MM/YYYY" />
            </b-col>
            <b-col cols="12" md="3">
              <label for="date_start">Fecha Fin</label>
              <flat-pickr id="date_start" v-model="filter.end" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                placeholder="DD/MM/YYYY" />
            </b-col>

            <b-col cols="12" md="3">
              <div class="demo-inline-spacing">
                <b-button size="sm" @click="getCreditLimits" variant="primary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                  <feather-icon icon="SearchIcon" />
                  Buscar
                </b-button>

                <b-button size="sm" v-if="filterCustomers.length > 0" @click="printReport" variant="secondary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                  <feather-icon icon="PrinterIcon" />
                  Imprimir
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card no-body class="mb-0">
        <div class="table-responsive_ small text-small">
          <h5 class="d-none print-title ml-1 text-capitalize">{{ customFormatDate(filter.start) }} al {{ customFormatDate(filter.end) }}</h5>
          <table
            class="table table-responsive_ table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100">
            <thead>
              <tr>
                <th>CLIENTE</th>
                <th>USUARIO</th>
                <th>LÍMTE ACTUAL</th>
                <th>INCREMENTO</th>
                <th>FECHA ACTUALIZACIÓN</th>
                <th>COMENTARIOS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="filterCustomers.length <= 0">
                <td colspan="6">
                  <h6 class="text-center mt-1">
                    No hay registros
                  </h6>
                </td>
              </tr>
              <tr v-else v-for="(item, index) in filterCustomers" :key="index">
                <td>{{ item.cliente_Nombre }}</td>
                <td>{{ item.nombre_Usuario }}</td>
                <td>{{ formatCurrency(item.limite_Actual) }}</td>
                <td>{{ formatCurrency(item.limite_Nuevo) }}</td>
                <td class="text-capitalize">{{ formatDate(item.fecha_Actualizacion) }}</td>
                <td>{{ item.comentarios }}</td>
              </tr>
            </tbody>
            <tbody>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardText,
  BCardBody,
  BTable,
  BTableLite,
  BOverlay,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,

} from 'bootstrap-vue'
import { ref, onMounted, computed } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";

import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import moment from 'moment';

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    //
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  setup() {

    const loading = ref(false)
    const customers = ref([])
    const search = ref(null)

    const filter = ref({
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    })

    const getCreditLimits = async () => {
      const query = {
        idCliente: 0,
        fechaIni: moment(filter.value.start).startOf('day').format('YYYY-MM-DD H:mm:ss'),
        fechaFin: moment(filter.value.end).endOf('day').format('YYYY-MM-DD H:mm:ss'),
      }

      try {
        loading.value = true
        const { data } = await CustomersResource.getCreditLimit(query)
        loading.value = false
        customers.value = _.orderBy(data.data, ['cliente_Nombre', 'fecha_Actualizacion'], ['asc', 'desc'])
        console.log(data)
      }catch(e){
        loading.value = false
      }
    }

    const filterCustomers = computed(() => {
        return customers.value;
    })

    const printReport = () => {
      window.print()
    }

    onMounted(async () => {
      // await getPrices()
    })

    return {
      search,
      loading,
      filter,

      //Computed
      filterCustomers,

      //Methods
      getCreditLimits,
      printReport,
    };
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }

    .print-title {
      display: block !important;
    }
  }
}
</style>


<style>
.ag-theme-quartz.large,
.ag-theme-quartz-dark.large {
  --ag-grid-size: 10px;
  --ag-list-item-height: 40px;
  --ag-font-size: 32px;
}

.ag-theme-quartz.compact,
.ag-theme-quartz-dark.compact {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
  --ag-font-size: 10px;
}


.flatpickr-small .flatpickr-input {
    /*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
    padding: 5px; /* Ajusta el padding del input */
    /*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
    /*width: 150px!important; /* Ajusta el ancho del input */
    height: 30px!important; /* Ajusta la altura del input */
    /*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}
</style>
